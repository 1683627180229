import styled from "styled-components";
import { Container } from "styles/Common";
import InnerFolder from "./InnerFolder";
import { device } from "styles/Breakpoint";

function index() {
  return (
    <PackSection>
      <Container>
        <Folder>
          <Beads>
            <svg
              width="370"
              height="42"
              viewBox="0 0 370 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 20C0 8.9543 8.95431 0 20 0H321.429C326.902 0 332.136 2.2433 335.911 6.2069L370 42H0V20Z"
                fill="var(--beads-box)"
              />
              <circle cx="33.5" cy="25.5" r="4.5" fill="var(--beads)" />
              <circle cx="50.5" cy="25.5" r="4.5" fill="var(--beads)" />
              <circle cx="67.5" cy="25.5" r="4.5" fill="var(--beads)" />
            </svg>
          </Beads>
          <InnerFolder />
        </Folder>
      </Container>
    </PackSection>
  );
}

export default index;

const PackSection = styled.section`
  background-color: var(--white);
  padding-bottom: 60px;
`;

const Folder = styled.div``;

const Beads = styled.div`
  @media ${device.mobileL} {
    svg {
      width: 200px;
    }
  }
`;
