import styled from "styled-components";
import { SectionTitle } from "styles/Common";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ProjectCard from "components/card/ProjectCard";
import useTranslation from "next-translate/useTranslation";
import { device } from "styles/Breakpoint";
import React, { Fragment } from "react";

interface props {
  work: Iproject[];
}

function index({ work }: props) {
  const { t } = useTranslation("common");

  return (
    <LatestProject>
      <SectionTitle>{t("latest.title")}</SectionTitle>

      <LatestSlider>
        <Swiper
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={1.5}
          navigation={{
            prevEl: ".custom-prev-btn",
            nextEl: ".custom-next-btn",
          }}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {work.map((d: Iproject) => (
            <SwiperSlide key={d.id}>
              <ProjectCard data={d} />
            </SwiperSlide>
          ))}

          <PrvArrow className="custom-prev-btn">
            <svg
              width="270"
              height="270"
              viewBox="0 0 270 270"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_bd_4_2489)">
                <circle
                  cx="135"
                  cy="110"
                  r="60"
                  fill="white"
                  fillOpacity="0.8"
                  shapeRendering="crispEdges"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.164 107.041L137.612 102.322C137.992 101.918 138.604 101.922 138.979 102.331C139.353 102.739 139.349 103.397 138.969 103.8L134.518 108.522C134.144 108.915 133.937 109.438 133.933 109.995C133.929 110.553 134.128 111.078 134.493 111.474L138.866 116.253C139.24 116.662 139.235 117.32 138.854 117.723C138.473 118.125 137.861 118.12 137.487 117.711L133.117 112.935C132.39 112.147 131.992 111.097 132 109.981C132.008 108.866 132.422 107.821 133.164 107.041Z"
                fill="#060714"
              />
              <defs>
                <filter
                  id="filter0_bd_4_2489"
                  x="0"
                  y="0"
                  width="270"
                  height="270"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_4_2489"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="25" />
                  <feGaussianBlur stdDeviation="37.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0235294 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_4_2489"
                    result="effect2_dropShadow_4_2489"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_4_2489"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </PrvArrow>

          <NxtArrow className="custom-next-btn">
            <svg
              width="270"
              height="270"
              viewBox="0 0 270 270"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_bd_4_2489)">
                <circle
                  cx="135"
                  cy="110"
                  r="60"
                  fill="white"
                  fillOpacity="0.8"
                  shapeRendering="crispEdges"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M138.06 112.939L133.651 117.695C133.274 118.101 132.662 118.102 132.284 117.697C131.906 117.292 131.905 116.634 132.282 116.227L136.694 111.469C137.065 111.073 137.268 110.549 137.267 109.991C137.267 109.433 137.063 108.91 136.695 108.517L132.283 103.774C131.906 103.368 131.906 102.71 132.283 102.304C132.661 101.899 133.272 101.899 133.65 102.304L138.059 107.044C138.793 107.827 139.199 108.874 139.2 109.989C139.201 111.105 138.796 112.153 138.06 112.939Z"
                fill="#060714"
              />
              <defs>
                <filter
                  id="filter0_bd_4_2489"
                  x="0"
                  y="0"
                  width="270"
                  height="270"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_4_2489"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="25" />
                  <feGaussianBlur stdDeviation="37.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0235294 0 0 0 0 0.027451 0 0 0 0 0.0784314 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_4_2489"
                    result="effect2_dropShadow_4_2489"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_4_2489"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </NxtArrow>
        </Swiper>
      </LatestSlider>
    </LatestProject>
  );
}

export default index;

const LatestProject = styled.section`
  padding: 50px 0;
  background-color: var(--white);
`;

const LatestSlider = styled.div`
  padding: 40px 0;
  .slick-track {
    display: flex;
  }
  .slick-slide {
    padding: 20px;
    height: inherit !important;
    & > div {
      height: 100%;
    }
    @media ${device.laptop} {
      padding: 10px;
    }
  }
`;

const NxtArrow = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  z-index: 2;
  svg {
    width: 120px;
    width: 120px;
    @media ${device.laptopL} {
      width: 60px;
      height: 60px;
    }
  }
  &:before {
    display: none;
  }
  &.slick-disabled {
    display: none;
  }
  @media ${device.laptop} {
    right: 0;
  }
`;

const PrvArrow = styled.div`
  cursor: pointer;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: auto;
  height: auto;
  z-index: 2;
  svg {
    width: 120px;
    width: 120px;

    @media ${device.laptopL} {
      width: 60px;
      height: 60px;
    }
  }
  &:before {
    display: none;
  }
  &.slick-disabled {
    display: none;
  }
  @media ${device.laptop} {
    left: 0;
  }
`;
