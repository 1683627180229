//Age
export const AgeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="var(--black)"
      width="100px"
      height="100px"
      viewBox="0 0 256 256"
      id="Flat"
      stroke="var(--black)"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <path d="M95.8877,48a32,32,0,1,1,32,32A32.03635,32.03635,0,0,1,95.8877,48Zm132.22851,73.13965C226.40527,120.11328,185.5542,96,128,96S29.59473,120.11328,27.88379,121.13965a8.00047,8.00047,0,0,0,8.23242,13.7207c.36133-.21686,34.9165-20.59765,83.88379-22.676v36.77447L58.02051,218.68555a7.99985,7.99985,0,1,0,11.959,10.6289L128,164.042l58.02051,65.27246a7.99985,7.99985,0,1,0,11.959-10.6289L136,148.95886V112.18567c48.769,2.08642,83.5293,22.46252,83.88379,22.67468a8.00047,8.00047,0,0,0,8.23242-13.7207Z" />{" "}
      </g>
    </svg>
  );
};

//Projects
export const ProjectSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100px"
      height="100px"
      viewBox="0 0 24 24"
      version="1.1"
      fill="var(--black)"
      stroke="var(--black)"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_iconCarrier">
        <title />

        <g
          fill="none"
          fillRule="evenodd"
          id="页面-1"
          stroke="none"
          strokeWidth="1"
        >
          <g
            id="导航图标"
            stroke="var(--black)"
            strokeWidth="1.5"
            transform="translate(-177.000000, -334.000000)"
          >
            <g id="官方网站" transform="translate(177.000000, 334.000000)">
              <g id="编组" transform="translate(3.000000, 4.000000)">
                <rect
                  height="4.5"
                  id="矩形"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  width="5"
                  x="7"
                  y="12"
                />

                <rect height="12" id="矩形" rx="1" width="19" x="0" y="0" />

                <line
                  id="路径"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  x1="8.5"
                  x2="10.5"
                  y1="9.5"
                  y2="9.5"
                />

                <line
                  id="路径"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  x1="4.5"
                  x2="14.5"
                  y1="16.5"
                  y2="16.5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

//New tab link
export const LinkSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 2.00001L11.75 12.25"
        stroke="var(--purple-light)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9998 6.49999L21.9998 1.99999L17.0568 1.99999"
        stroke="var(--purple-light)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V12.75"
        stroke="var(--purple-light)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
