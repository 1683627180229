import { useRef, useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";
import useTranslation from "next-translate/useTranslation";
import { device } from "styles/Breakpoint";
import { TopTitle } from "styles/Common";

function Text() {
  const txt1 = useRef<HTMLDivElement>(null);
  const txt2 = useRef<HTMLDivElement>(null);
  const txt3 = useRef<HTMLDivElement>(null);

  const { t } = useTranslation("common");

  useEffect(() => {
    const tl = gsap.timeline();

    if (txt1.current)
      tl.from(txt1.current.children, 1.4, {
        y: 200,
        ease: "power4.out",
        skewY: 7,
        stagger: {
          amount: 0.3,
        },
      });

    if (txt2.current)
      tl.from(txt2.current.children, 1.4, {
        y: 200,
        ease: "power4.out",
        skewY: 7,
        stagger: {
          amount: 0.3,
        },
      });

    if (txt3.current)
      tl.fromTo(
        txt3.current,
        {
          opacity: 0,
          ease: "power4.out",
        },
        {
          opacity: 1,
        }
      );
  }, []);

  return (
    <>
      <TopTitle>{t("banner.top")}</TopTitle>
      <Styles ref={txt1}>
        <span>{t("banner.middle")}</span>
      </Styles>
      <Styles ref={txt2}>
        <span style={{ color: "#5DD2F3" }}>React/Next</span>
      </Styles>
      <Routine ref={txt3}>
        {t("banner.bottom1")} <s>{t("banner.bottom2")}</s> {t("banner.bottom3")}
      </Routine>
    </>
  );
}

export default Text;

const Styles = styled.div`
  overflow: hidden;
  @media ${device.mobileL} {
    text-align: center;
  }
  span {
    font-size: 80px;
    font-weight: 600;
    color: var(--black);
    display: inline-block;
    @media ${device.laptopL} {
      font-size: 60px;
    }
    @media ${device.laptop} {
      font-size: 40px;
    }
    @media ${device.mobileL} {
      font-size: 30px;
    }
  }
`;

const Routine = styled.div`
  letter-spacing: 0.2px;
  font-size: 24px;
  text-align: center;
  color: var(--grayWhite);
  @media ${device.laptopL} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;
