import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "styles/Common";
import { darkModeAtom } from "utils/ThemeAtom";
import Icons from "./Icons";
import Text from "./Text";

function index() {
  const [darkMode] = useAtom(darkModeAtom);
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    setTheme(darkMode);
  }, [darkMode]);

  return (
    <SectionBanner $dark={theme}>
      <Container>
        <Field>
          <FieldText>
            <Text />
          </FieldText>

          <Icons />
        </Field>
      </Container>
    </SectionBanner>
  );
}

export default index;

const SectionBanner = styled.section<{ $dark: boolean }>`
  background-image: ${({ $dark }) =>
    $dark
      ? 'url("/img/home/banner-bck-dark.webp")'
      : 'url("/img/home/banner-bck.webp")'};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--white);
`;

const Field = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
`;

const FieldText = styled.div``;
