import styled from "styled-components";

interface props {
  data: string[];
}

function Cast({ data }: props) {
  return (
    <CastItem>
      <CTitle>{data[0]}</CTitle>
      <CVersion>V{data[1]}</CVersion>
    </CastItem>
  );
}

export default Cast;

const CastItem = styled.div`
  padding: 10px;
`;

const CTitle = styled.div`
  font-size: 17.3303px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.216628px;
  color: var(--black);
`;

const CVersion = styled.div`
  font-size: 15.164px;
  line-height: 150%;
  letter-spacing: 0.216628px;
  color: #7780a1;
`;
