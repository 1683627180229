
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Layout from "components/Layout";
import Banner from "components/Home/Banner";
import Introduction from "components/Home/Introduction";
import Latest from "components/Home/Latest";
import Packs from "components/Home/Packs";
const getServerSideProps = async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_BASE_API}/project?per_page=10&_embed`);
    const data = await res.json();
    const optData: Iproject = data.map((el: any) => {
        return {
            id: el.id,
            image: el._embedded["wp:featuredmedia"]["0"].source_url,
            title: el.title.rendered,
            categories: el.categories,
        };
    });
    if (!data) {
        console.log("error");
        return {
            notFound: true,
        };
    }
    return {
        props: { data: optData }, // will be passed to the page component as props
    };
};
interface props {
    data: Iproject[];
}
export default function Home({ data }: props) {
    return (<Layout title="Sonahang Rai - Personal Site" description="Hello, I am Sonahang Rai. I am a frontend developer from Kathmandu, Nepal. I code in REACT and NEXT javascript to create a reactive web interface. I also have experince in MERN stack development." image="https://www.sonahangrai.com/wp-content/uploads/2021/08/1606228885780.jpg">
      <Banner />
      <Introduction />
      <Latest work={data}/>
      <Packs />
    </Layout>);
}

    async function __Next_Translate__getServerSideProps__191ee8619ab__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__191ee8619ab__ as getServerSideProps }
  