import Image from "next/image";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import Stack from "components/Stack";
import useTranslation from "next-translate/useTranslation";
import { device } from "styles/Breakpoint";

interface props {
  data: Iproject;
}

function ProjectCard({ data }: props) {
  const { t } = useTranslation("common");

  return (
    <CardProject>
      <ProjImg>
        <Image src={data.image} height={240} width={400} alt="" />
      </ProjImg>
      <NameRow>
        <PName>{data.title}</PName>
        <Visit>
          <Link href={`/works/${data.id}`}>{t("latest.visit")}</Link>
        </Visit>
      </NameRow>
      <StackRow>
        <Stack data={data.categories} />
      </StackRow>
    </CardProject>
  );
}

export default ProjectCard;

const CardProject = styled.div`
  border: 0.5px solid #e2e2ed;
  padding: 24px;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.laptopL} {
    padding: 10px;
  }
`;

const ProjImg = styled.div`
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
    @media ${device.desktop} {
      width: 100%;
    }
  }
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid #e2e2ed;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const PName = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--black);
  @media ${device.laptopL} {
    font-size: 18px;
  }
  @media ${device.laptopL} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Visit = styled.div`
  @media ${device.tablet} {
    width: 100%;
  }
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: var(--purple-dark);
    padding: 10px 20px;
    background: rgba(42, 39, 201, 0.1);
    border-radius: 4px;
    @media ${device.laptopL} {
      font-size: 14px;
    }
  }
`;

const StackRow = styled.div``;
