import {
  HtmlSvg,
  JsSvg,
  NextSvg,
  ReactSvg,
  ReduxSvg,
  StyledSvg,
} from "icons/stack";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { darkModeAtom } from "utils/ThemeAtom";
import { device } from "styles/Breakpoint";

function Icons() {
  const [darkMode] = useAtom<boolean>(darkModeAtom);
  const [dark, setDark] = useState<boolean>(false);

  useEffect(() => {
    setDark(darkMode);
  }, [darkMode]);

  return (
    <>
      <FloatingIcons $dark={dark || false} left={0} top={20} title="ReactJS">
        <ReactSvg />
      </FloatingIcons>

      <FloatingIcons
        $dark={dark || false}
        left={90}
        top={20}
        title="NextJs"
        $invert={true}
      >
        <NextSvg />
      </FloatingIcons>

      <FloatingIcons
        $dark={dark || false}
        left={10}
        top={80}
        title="Styled Components"
        $invert={true}
      >
        <StyledSvg />
      </FloatingIcons>

      <FloatingIcons $dark={dark || false} left={90} top={80} title="Redux">
        <ReduxSvg />
      </FloatingIcons>

      <FloatingIcons $dark={dark || false} left={50} top={10} title="HTML">
        <HtmlSvg />
      </FloatingIcons>

      <FloatingIcons $dark={dark || false} left={50} top={80} title="JS">
        <JsSvg />
      </FloatingIcons>
    </>
  );
}

export default Icons;

const FloatingIcons = styled.div<{
  left: number;
  top: number;
  title: string;
  $dark: boolean;
  $invert?: boolean;
}>`
  position: absolute;
  left: ${({ left }) => `${left}%`};
  top: ${({ top }) => `${top}%`};
  border-radius: 20px;
  background-color: ${({ $dark }) => ($dark ? "#080819" : "#fff")};
  box-shadow: ${({ $dark }) =>
    $dark ? "1px 6px 9px 1px #0a1a4b" : "1px 6px 9px 1px #F1F1F6"};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 60px;
    height: 60px;
    path {
      fill: ${({ $invert, $dark }) => $invert && $dark && "#fff"};
    }
    @media ${device.tablet} {
      width: 40px;
      height: 40px;
    }
    @media ${device.mobileL} {
      width: 30px;
      height: 30px;
    }
  }
  @media ${device.tablet} {
    left: ${({ left }) => `${Math.abs(left - 10)}%`};
    top: ${({ top }) => `${Math.abs(top - 10)}%`};
  }

  &:after {
    content: ${({ title }) => title};
    position: absolute;
  }
`;
