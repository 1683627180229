import styled from "styled-components";
import lottieJson from "public/lottie/developer-skills.json";
import dynamic from "next/dynamic";

function Graphics() {
  const Lottie = dynamic(() => import("react-lottie-player"), { ssr: false });

  return (
    <IntroGraphics>
      <Lottie
        play
        loop
        animationData={lottieJson}
        style={{ height: "300px", width: "300px" }}
      />
    </IntroGraphics>
  );
}

export default Graphics;

const IntroGraphics = styled.div``;
