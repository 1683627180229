import { AgeSvg, ProjectSvg } from "icons/infograph";
import { useState, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import styled from "styled-components";
import { device } from "styles/Breakpoint";
import { TopTitle, Title, Para } from "styles/Common";

function MyIntro() {
  const { t } = useTranslation("common");
  const [totalProject, setTotalProject] = useState(0);

  function calculateAge(birthdate: string) {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  //Get project count
  async function getProj() {
    let res = await fetch(
      `${process.env.NEXT_PUBLIC_BASE_API}/project?per_page=100`
    );
    return await res.json();
  }

  useEffect(() => {
    getProj().then(
      (res) => {
        setTotalProject(res.length);
      },
      (err) => {
        setTotalProject(20);
      }
    );
  }, []);

  return (
    <IntroBox>
      <TopTitle align="left">{t("about.about_me")}</TopTitle>
      <Title>Sonahang Bantawa Mangpahang Rai</Title>
      <Para>{t("about.info")}</Para>

      <Grids>
        <Item>
          <Icon>
            <AgeSvg />
          </Icon>
          <ItemTitle>
            {t("about.age")} ({t("about.age_txt")})
          </ItemTitle>
          <ItemData>
            {calculateAge("1998-06-11")} {t("about.year")}
          </ItemData>
        </Item>
        <Item>
          <Icon>
            <ProjectSvg />
          </Icon>
          <ItemTitle>
            {t("about.project")} ({t("about.proj_txt")})
          </ItemTitle>
          <ItemData>{totalProject}+</ItemData>
        </Item>
      </Grids>
    </IntroBox>
  );
}

export default MyIntro;

const IntroBox = styled.div``;

const Grids = styled.div`
  display: flex;
  gap: 20px;
  @media ${device.mobileL} {
    flex-wrap: wrap;
  }
`;

const Item = styled.div`
  @media ${device.mobileL} {
    width: 100%;
    text-align: center;
  }
`;

const Icon = styled.div`
  svg {
    width: 30px;
    height: 30px;
  }
`;

const ItemTitle = styled.div`
  color: var(--grayWhite);
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
`;

const ItemData = styled.div`
  color: var(--grayWhite);
`;
