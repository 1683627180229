import React from "react";
import styled from "styled-components";
import { device } from "styles/Breakpoint";
import { Container } from "styles/Common";
import Graphics from "./Graphics";
import MyIntro from "./MyIntro";

function index() {
  return (
    <IntroSection>
      <Container>
        <Row>
          <Visual>
            <Graphics />
          </Visual>
          <Intro>
            <MyIntro />
          </Intro>
        </Row>
      </Container>
    </IntroSection>
  );
}

export default index;

const IntroSection = styled.section`
  padding: 100px 0;
  background-color: var(--white);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media ${device.laptop} {
    flex-wrap: wrap;
  }
`;

const Visual = styled.div`
  width: calc(100% / 2 - 40px);
  @media ${device.laptop} {
    width: 100%;
  }
`;

const Intro = styled.div`
  width: calc(100% / 2 - 40px);
  @media ${device.laptop} {
    width: 100%;
  }
`;
