import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { TopTitle, Title, Para } from "styles/Common";
import packages from "package.json";
import Cast from "./Cast";
import gsap from "gsap";
import { useAtom } from "jotai";
import { darkModeAtom } from "utils/ThemeAtom";
import { device } from "styles/Breakpoint";
import useTranslation from "next-translate/useTranslation";

function InnerFolder() {
  const castRef = useRef<HTMLDivElement>(null);
  const [dark, setDark] = useState(false);
  const [darkAtom] = useAtom(darkModeAtom);
  const { t } = useTranslation("common");

  useEffect(() => {
    darkAtom ? setDark(true) : setDark(false);
  }, [darkAtom]);

  useEffect(() => {
    let tl = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        trigger: castRef.current,
        start: "top center",
        end: "+=400",
      },
    });
    if (castRef.current)
      tl.set(castRef.current, { y: 500 }).to(
        castRef.current,
        castRef.current.clientHeight / 50,
        {
          y: -castRef.current.clientHeight + 30,
          ease: "Power0.easeNone",
        }
      );
  }, []);

  return (
    <Folder $dark={dark}>
      <Left>
        <TopTitle align="left">{t("packs.packages")}</TopTitle>
        <Title>{t("packs.title")}</Title>
        <Para>{t("packs.desc")}</Para>
      </Left>
      <Right>
        <MovieCast ref={castRef}>
          <TopTitle>{t("packs.dependencies")}</TopTitle>
          {Object.entries(packages.dependencies).map((d) => (
            <Cast key={d[0] + "" + d[1]} data={d} />
          ))}
          <br />
          <TopTitle>{t("packs.devDependencies")}</TopTitle>
          {Object.entries(packages.devDependencies).map((d) => (
            <Cast key={d[0] + "" + d[1]} data={d} />
          ))}
        </MovieCast>
      </Right>
    </Folder>
  );
}

export default InnerFolder;

const Folder = styled.div<{ $dark: boolean }>`
  background: ${({ $dark }) =>
    $dark
      ? "linear-gradient(188.08deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 93.79%), #060714;"
      : " #f2f3f6"};
  border-radius: 0px 20px 20px 20px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  padding: 50px;
  width: 600px;
  @media ${device.laptopL} {
    width: 500px;
  }
  @media ${device.laptop} {
    width: 400px;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Right = styled.div`
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
  flex-grow: 2;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const MovieCast = styled.div`
  text-align: center;
`;
